.hero-text {
  font-family: Montserrat, arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.45rem;
  letter-spacing: 0.03em;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  color: white;
  background: linear-gradient(
    268.72deg,
    rgba(178, 216, 184, 0.4) -24.33%,
    rgba(42, 82, 48, 0.5) 28.99%,
    #113d18 94.83%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

ymaps[class*="map-copyrights-promo"] {
  display: none;
}

.bg-blur {
  background: #d9d9d924;
  backdrop-filter: blur(8.0213px);
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.width-fit {
  width: fit-content;
}

.overflow-auto {
  overflow: auto;
}

.overflow-word {
  overflow-wrap: break-word;
}

.z-index-1 {
  z-index: 1;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.m-0 {
  margin: 0 !important;
}

.mb-0-25 {
  margin-bottom: 0.25rem;
}

.mb-0-5 {
  margin-bottom: 0.5rem;
}

.mb-0-75 {
  margin-bottom: 0.75rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-minus-0-4 {
  margin-left: -0.4rem;
}

.ml-0-25 {
  margin-left: 0.25rem;
}

.ml-0-8 {
  margin-left: 0.8rem;
}

.mr-minus-0-4 {
  margin-right: -0.4rem;
}

.mr-0-25 {
  margin-right: 0.25rem;
}

.mr-0-2 {
  margin-right: 0.2rem;
}

.mr-0-5 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.p-1 {
  padding: 1rem;
}

.pl-0-4 {
  padding-left: 0.4rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-0-4 {
  padding-right: 0.4rem;
}

.pr-1 {
  padding-right: 1rem;
}

.max-with-25 {
  max-width: 25rem;
}

.w-4-5 {
  width: 4.5rem;
}

.w-5 {
  width: 5rem;
}

.w-10 {
  width: 10rem;
}

.w-13 {
  width: 13rem;
}

.w-15 {
  width: 15rem;
}

.w-16 {
  width: 16rem;
}

.w-100vw {
  width: 100vw;
}

.w-half {
  width: 50%;
}

.w-full {
  width: 100% !important;
}

.h-3 {
  height: 3rem;
}

.h-20 {
  height: 20rem;
}

.h-full {
  height: 100% !important;
}

@media screen and (min-width: 681px) {
  .hide-on-desktop {
    display: none !important;
  }

  .justify-center-desktop {
    justify-content: center;
  }

  .w-half-desktop {
    width: 50%;
  }

  .flex-column-desktop {
    flex-direction: column;
  }

  .mr-4-desktop {
    margin-right: 1rem !important;
  }
}

@media screen and (max-width: 680px) {
  .hide-on-mobile {
    display: none !important;
  }

  .flex-column-mobile {
    flex-direction: column;
  }
}

.text-hover-underline:hover {
  text-decoration: underline;
}

.text-underline {
  text-decoration-line: underline;
}

.text-dotted {
  text-decoration-style: dotted;
  text-decoration-line: underline;
}

.underline {
  text-decoration: underline;
}

.color-red {
  color: red;
}

.color-link {
  color: #485fc7;
}

.position-relative {
  position: relative;
}

.font-weight-bold {
  font-weight: bold;
}

.wh-100 {
  width: 100%;
  height: 100%;
}

.position-absolute {
  position: absolute;
}

.flex-column {
  flex-direction: column;
}

@media screen and (max-width: 680px) {
  .flex-column-m {
    flex-direction: column;
  }

  .flex-1-m {
    flex: 1;
  }

  .mb-2-m {
    margin-bottom: 2rem !important;
  }

  .flex-row-m {
    flex-direction: row;
  }

  .pb-0-m {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 680px) {
  .flex-column-d {
    flex-direction: column;
  }

  .height-100-d {
    height: 100%;
  }

  .font-size-1-4-d {
    font-size: 1.4rem;
  }

  .flex-1-d {
    flex: 1;
  }

  .flex-row-d {
    flex-direction: row;
  }

  .mb-3-d {
    margin-bottom: 3rem !important;
  }

  .max-width-30-d {
    max-width: 30rem;
  }
}

.color-white {
  color: white;
}

.height-100 {
  height: 100%;
}

.min-height-100 {
  min-height: 100%;
}

.blur-bg {
  background: rgba(217, 217, 217, 0.01);
  backdrop-filter: blur(8.0213px);
}

.pv-20-percent {
  padding-bottom: 20%;
  padding-top: 20%;
}

.text-shadow {
  text-shadow: 1px 1px 2px #000000;
}

.has-font-size-2 {
  font-size: 1.1rem;
}

.border-bottom-dashed {
  border-bottom: 1px dashed;
}

.h-100 {
  height: 100%;
}

input[type=checkbox].is-medium {
  transform: scale(1.4);
}

