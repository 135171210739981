.logo-text {
  font-family: Montserrat, arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: #113d18;
  padding-left: 0.5rem;
  border-left: 1px solid black;
}

.logo-expat {
  background-image: url("../../assets/img/logo_expat_kovcheg.png");
  height: 1.5rem;
  width: 7rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.main-page .logo-expat {
  height: 3rem;
  width: 15rem;
}
