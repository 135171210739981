@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@400;500&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow: auto;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  font-family: "Roboto", arial, sans-serif;
}

.main-wrapper {
  height: 100%;
  background-color: #fff;
}

.icon-wrapper svg {
  width: 1.3rem;
  height: 1.3rem;
}

.close-dark {
  /*background: url('icons/close-dark.svg') no-repeat;*/
  background: url("../icons/close-dark.svg") no-repeat;
  cursor: pointer;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-position: center;
  background-color: #ffffffcb;
  border-radius: 0.4rem;
}

.share {
  /*background: url('icons/close-dark.svg') no-repeat;*/
  background: url("../icons/share.svg") no-repeat;
  background-position: center;
  cursor: pointer;
  width: 1.1rem;
  height: 1.1rem;
  margin: 0.2rem;
  position: absolute;
  top: 1rem;
  right: 3rem;
  background-position: center;
  background-color: #fff;
  border-radius: 0.4rem;
  opacity: 0.6;
}

.share:hover,
.share:focus {
  opacity: 0.9;
}
