.marker-center-icon {
  position: absolute;
  left: 48%;
  top: 51%;
  transform: translate(0, -100%);
  pointer-events: none;
}

.display-position {
  position: absolute;
  top: 0;
  z-index: 1;
}

.tooltip-info {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2rem;
  height: 2rem;
}

.use-this-point {
  color: #113d18;
  font-size: 0.9rem;
}

.css-kudwh-MuiTooltip-arrow {
  color: #f5f5f9 !important;
}

.css-kudwh-MuiTooltip-arrow::before {
  border: 1px solid #dadde9;
}

.use-this-point-address {
  font-weight: 300;
}

.MuiTooltip-popper {
  opacity: 0.9;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #fff!important;
}

.hint-text {
  font-weight: 100;
  color: #555555a1;
  font-size: 0.7rem;
}
