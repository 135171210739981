.list-items {
  overflow-x: auto;
}

.menu-item {
  background-color: #b2d8b8;
}

.top-level-item > p {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);

  background: #b2d8b8;
  width: 100%;
  display: inline-block;
  border-radius: 0.625rem;
  padding: 0.45rem 0.6rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #113d18;
}

.top-level-item.open > p {
  background-color: #fcf4db;
}

.second-level-items {
  background-color: #fcf4db;
  border-radius: 0.625rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  max-height: 0;
  padding: 0;
  overflow-y: auto;
  transition: max-height 0.05s;
}

.open .second-level-items {
  padding: 1rem 0.5rem;
  padding-left: 1.1rem;
  height: auto;
  max-height: 10rem;
  margin-bottom: 0.5rem;
}

.sub-level-title {
  align-items: center;
  display: flex;
  cursor: pointer;
}

.third-level-items {
  max-height: 0;
  overflow-y: auto;
  transition: max-height 0.05s;
}

.second-level-item.open {
  background-color: green;
}

.open-third .third-level-items {
  padding: 0.45rem 0.6rem;
  padding-bottom: 0;
  height: auto;
  max-height: 10rem;
}

.third-level-items li {
  margin-bottom: 0.5rem;
}
