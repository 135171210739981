.add-object-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    background: #113D18;
    border-radius: 0.5rem;
    color: #FCF4DB;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
}
