.right-pane {
  height: calc(100% - 4rem);
  max-width: 35%;
  min-width: 35%;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  position: absolute;
  right: 0;
  top: 4rem;
  flex: 3;
  z-index: 410;
}

.mobile-menu-toggle {
  display: none;
  cursor: pointer;
}

.show-item.only-mobile {
  display: none;
}

.show-item.only-desktop {
  display: none;
}

@media screen and (min-width: 681px) {
  .hero-text {
    text-align: center;
  }

  .show-item.only-desktop {
    display: block;
  }
}

@media screen and (max-width: 680px) {
  .mobile-menu-toggle {
    display: block;
  }

  .right-pane {
    max-width: 0;
    min-width: 0;
    padding: 0;
    display: none;
  }

  .right-pane.menu-is-opened {
    max-width: 100%;
    min-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    z-index: 1;
    display: flex;
  }

  .show-item.only-mobile {
    position: absolute;
    bottom: 3%;
    left: 3%;
    display: block;
    width: 94%;
  }
}

.show-item {
  flex: 1;
  min-height: 14rem;
  background: #ffffffe0;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-y: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));
}

.search-input {
  border: 1px solid #113d18;
  border-radius: 10px;
  background-color: transparent;
}

.list-mode-toggle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.19rem;
  text-align: center;
  text-decoration-line: underline;
  color: #113d18;
  text-align: left;
}

.top-menu {
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-telegram-link {
  color: #113d18;
  opacity: 0.8;
  display: flex;
  align-items: center;
}

.map-telegram-link:hover {
  color: #fcf4db;
  opacity: 1;
}

.map-telegram-icon {
  width: 1.2rem;
  height: 1.2rem;
  color: #113d18;
}

.map-wrapper {
  height: 100vh;
  width: 100vw;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.content {
  width: calc(100% - 250px);
  padding: 40px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.position-info {
  position: absolute;
  bottom: 10rem;
  z-index: 2;
  background: white;
}

.add-object-button-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background: #113d18;
  border-radius: 0.5rem;
  color: #fcf4db;
  position: fixed;
  bottom: 5rem;
  left: 1rem;
}

.select-languages .dropdown-heading-value {
  width: 6rem !important;
  overflow: hidden !important;
}

.select-languages-map-page {
  min-width: 9rem;
}

.leaflet-control-container {
  display: none;
}

.copyright-openstreetmap {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 410;
  background: #ffffff26;
  padding: 0rem 0.5rem;
  font-size: 0.8rem;
  border-top-left-radius: 0.5rem;
}
