.add-object-modal-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: #fff;
  color: #113d18;
  z-index: 420;
}

.close-modal {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}

.modal-body {
  background-color: #fdf4dc;
  height: 100%;
  border-radius: 0.5rem;
  min-width: 20rem;
  max-width: 40rem;
}

.underline-input {
  border: none;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid #113d18;
  border-radius: 0 !important;
}

.underline-input:focus {
  box-shadow: none;
  border-bottom: 1px solid #113d18;
}

.underline-textarea {
  border: 1px solid #113d18;
  border-radius: 0.3rem !important;
}

.underline-textarea:focus {
  box-shadow: none;
  border: 1px solid #113d18;
}

.button-outlined {
  background-color: transparent;
  border: 1px solid #113d18;
  border-radius: 0.5rem;
}

.button-main {
  background-color: #113d18;
  color: #fff;
  border-radius: 0.5rem;
}

.button-main:focus,
.button-main:hover {
  color: #fff;
}

.letter-counter-label {
  position: absolute;
  right: 0.6rem;
  bottom: 0.3rem;
  pointer-events: none;
  opacity: 0.5;
  font-size: 0.9rem;
}

.select-languages .dropdown-container {
  border: none !important;
  background: none !important;
  border-bottom: 1px solid #113d18 !important;
  border-radius: 0 !important;
}

.select-languages .dropdown-content {
  padding: 0!important;
  z-index: 412!important;
}

.select-languages .rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid #113d18 !important;
}

.select-languages .gray {
  color: #113d18 !important;
}

.select-languages .dropdown-heading-dropdown-arrow {
  position: absolute!important;
  right: 0!important;
}

.select-on-map:hover,
.select-on-map:focus {
  opacity: 0.8;
}

.select-on-map:active {
  opacity: 0.4;
}

.select-on-map-title {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  font-size: 1.3rem;
}

.invisible-input {
  height: 0;
  border: none;
  background: none;
  position: absolute;
  transform: translate(-50%, 0);
  opacity: 0;
}


